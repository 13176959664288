const wishes = {
  ha: {
    wish: 'Chúc em ngày càng trẻ đẹp, đảm đang, hạnh phúc',
    name: 'Ha Nguyen',
    avatar: require('../assets/photos/ha.jpg'),
    by: 'Minh Le',
  },
  linh: {
    wish: 'Chúc em ngày càng trẻ đẹp, đảm đang, hạnh phúc',
    name: 'Linh Vu',
    avatar: require('../assets/photos/linh.jpg'),
  },
  chesca: {
    wish: 'Happy women day, Chesca. Be always your self, beautiful and energetic',
    name: 'Louise Franchesca',
    avatar: require('../assets/photos/chesca.jpg'),
    by: 'Quan Nguyen',
  },
  nhung: {
    wish: 'Chúc chị luôn trẻ khỏe, vui vẻ và hạnh phúc',
    name: 'Nhung Tran',
    avatar: require('../assets/photos/nhung.png'),
  },
  thaotran: {
    wish: 'Chúc em ngày càng trẻ đẹp, đảm đang, hạnh phúc',
    name: 'Thao Tran',
    avatar: require('../assets/photos/thaotran.jpg'),
  },
  thaovu: {
    wish: 'Chúc em ngày càng trẻ đẹp, đảm đang, hạnh phúc',
    name: 'Thao Vu',
    avatar: require('../assets/photos/thaovu.jpg'),
  },
  thom: {
    wish: 'Chúc chị vợ luôn vui vẻ, hạnh phúc và xinh đẹp đặc biệt chăm sóc vợ em và anh Hoàng.',
    name: 'Thom Nguyen',
    avatar: require('../assets/photos/thom.jpg'),
    by: 'Cuong Pham'
  },
  thuy: {
    wish: 'Chúc chị hạnh phúc, vui vẻ, nấu ăn ngon',
    name: 'Thuy Nguyen',
    by: 'Anh Ta'
  },
  trang: {
    wish: 'Chúc chị luôn mạnh khỏe, nhiều niềm vui',
    name: 'Trang Do',
    by: 'Anh Ta'
  },
}

export default wishes